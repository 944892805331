import './axios.config';
import { common } from '../services/common';
import { emailTemplate } from './email-template';

const services = {
  common,
  emailTemplate,
};

export default services;
