import { request } from './axios.config';

export const emailTemplate = {
  async getEmailTemplate(id: number | string): Promise<IEmailTemplate> {
    return request.get<IEmailTemplate>('/api/email-templates/' + id, {});
  },
  async getEmailTemplateList(): Promise<ListResponse<IEmailTemplate>> {
    return request.get<ListResponse<IEmailTemplate>>('/api/email-templates', {
      params: {},
    });
  },
  async createEmailTemplate(data : {},
  ): Promise<IEmailTemplate> {
    return request.post<IEmailTemplate>('/api/email-templates', {
      ...data
    });
  },
  async updateEmailTemplate(
    id: string, data : {},
  ): Promise<IEmailTemplate> {
    return request.put<IEmailTemplate>('/api/email-templates/' + id, {
      ...data
    });
  },
  async deleteEmailTemplate(id: string): Promise<string> {
    return request.delete('/api/email-templates/' + id, {});
  },
};

export interface ListResponse<T> {
  list: T[];
  count: number;
}

export interface IEmailTemplate {
  id: string;
  jsonContent: string;
  htmlContent: string;
  templateName: string;
  createdAt: Date;
  lastModifiedAt: Date;
}
