import React, { Children, isValidElement } from 'react';

export function wrapWithComponent<P>(
  element: React.ReactNode | null | undefined,
  Component: React.ComponentType<P>,
  props: P,
): React.ReactNode {
  if (element == null) {
    return null;
  }

  return isElementOfType(element, Component) ? (
    element
  ) : (
      <Component {...props}>{element}</Component>
    );
}

const isComponent =
  process.env.NODE_ENV === 'development'
    ? hotReloadComponentCheck
    : (
      AComponent: React.ComponentType<any>,
      AnotherComponent: React.ComponentType<any>,
    ) => AComponent === AnotherComponent;


export function isElementOfType<P>(
  element: React.ReactNode | null | undefined,
  Component: React.ComponentType<P> | React.ComponentType<P>[],
): boolean {
  if (
    element == null ||
    !isValidElement(element) ||
    typeof element.type === 'string'
  ) {
    return false;
  }

  const { type: defaultType } = element;
  const overrideType = element.props?.__type__;
  const type = overrideType || defaultType;
  const Components = Array.isArray(Component) ? Component : [Component];

  return Components.some(
    (AComponent) => typeof type !== 'string' && isComponent(AComponent, type),
  );
}

export function elementChildren<T extends React.ReactElement>(
  children: React.ReactNode,
  predicate: (element: T) => boolean = () => true,
): T[] {
  return Children.toArray(children).filter(
    (child) => isValidElement(child) && predicate(child as T),
  ) as T[];
}

interface ConditionalWrapperProps {
  children: any;
  condition: boolean;
  wrapper: (children: any) => any;
}

export function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): JSX.Element {
  return condition ? wrapper(children) : children;
}

interface ConditionalRenderProps {
  condition: boolean;
  children: any;
}

export function ConditionalRender({
  condition,
  children,
}: ConditionalRenderProps): JSX.Element {
  return condition ? children : null;
}

function hotReloadComponentCheck(
  AComponent: React.ComponentType<any>,
  AnotherComponent: React.ComponentType<any>,
) {
  const componentName = AComponent.name;
  const anotherComponentName = (AnotherComponent as React.StatelessComponent<
    any
  >).displayName;

  return (
    AComponent === AnotherComponent ||
    (Boolean(componentName) && componentName === anotherComponentName)
  );
}