import axios from 'axios';


export const common = {
  async uploadImage(file: File | Blob): Promise<string> {
    var baseURLenv = import.meta.env.VITE_API_URL + "/api/upload";

    const data = new FormData();
    data.append('file', file);
    data.append('filename', file.name);

    const res = await axios.post<{ url: string }>(baseURLenv, data);
    return res.data.url;
  }
};