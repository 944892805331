import { emailTemplate, ITemplate } from '../services/email-template';
import createSliceState from './common/createSliceState';
import { Message } from '@arco-design/web-react';
import { history } from '../utils/history';
import { IBlockData, BlockManager, BasicType } from 'easy-email-core';
import { IEmailTemplate } from 'easy-email-editor';

export function getAdaptor(data: ITemplate): ITemplate {
  const content = JSON.parse(data.jsonContent).content as IBlockData;
  return {
    ...data,
    content,
    subject: data.templateName,
    subTitle: '',
  };
}

export default createSliceState({
  name: 'template',
  initialState: null as ITemplate | null,
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
  },
  effects: {
    fetchById: async (
      state,
      {id}: {id: string;}
    ) => {
      try {
        let data = await emailTemplate.getEmailTemplate(id)
        return getAdaptor(data);
      } catch (error) {
        history.replace('/');
        throw error;
      }
    },
    fetchDefaultTemplate: async (state) => {
      return {
        subject: '',
        subTitle: '',
        content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
      } as ITemplate;
    },
    create: async (
      state,
      payload: {
        template: ITemplate;
        htmlContent: any;
        success: (id: number) => void;
      }
    ) => {
      const data = await emailTemplate.createEmailTemplate({
        htmlContent: JSON.stringify(payload.htmlContent),
        jsonContent: JSON.stringify(payload.template),
        templateName: payload.template.subject
      });
      payload.success(data.id);
      return { ...data, ...payload.template };
    },
    updateById: async (
      state,
      payload: {
        id: string;
        template: ITemplate;
        htmlContent: any;
        success: (templateId: string) => void;
      }
    ) => {
      try {
        await emailTemplate.updateEmailTemplate(payload.id, {
          htmlContent: JSON.stringify(payload.htmlContent),
          jsonContent: JSON.stringify(payload.template),
          templateName: payload.template.subject
        });
        payload.success(payload.id);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          throw {
            message: 'Cannot change the default template',
          };
        }
      }
    },
    removeById: async (state, payload: { id: string; success: () => void; }) => {
      try {
        await emailTemplate.deleteEmailTemplate(payload.id);
        payload.success();
        Message.success('Removed success.');
      } catch (error: any) {
        if (error?.response?.status === 404) {
          throw {
            message: 'Cannot delete the default template',
          };
        }
      }
    },
  },
});
