import { useAppSelector } from '../../hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Frame from '../../components/Frame';
import templateList from '../../store/templateList';
import { Button } from '@arco-design/web-react';
import { CardItem } from './components/CardItem';
import { Stack } from '../../components/Stack';
import { history } from '../../utils/history';

export default function Home() {
  const dispatch = useDispatch();
  const list = useAppSelector('templateList');

  useEffect(() => {
    dispatch(templateList.actions.fetch(undefined));
  }, [dispatch]);

  return (
    <Frame
      title='Templates'
      primaryAction={
        <Button
          onClick={() => {
            history.push('/editor');
          }}
        >
          Add Template
        </Button>
      }
    >
      <>
        <Stack>
          {[...list].map((item) => (
            <CardItem data={item} key={item.id} />
          ))}
        </Stack>
      </>
    </Frame>
  );
}
