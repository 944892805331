import { ITemplate } from '../../../../services/email-template';
import React, { useCallback } from 'react';
import { IconEdit, IconDelete } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Popconfirm } from '@arco-design/web-react';
import { Link, useHistory } from 'react-router-dom';
import template from '../../../../store/email-template';
import { useDispatch } from 'react-redux';
import templateList from '../../../../store/templateList';
import { getLoadingByKey, useLoading } from '../../../../hooks/useLoading';
import { Loading } from '../../../../components/loading';

interface CardItemProps {
  data: ITemplate;
}

export function CardItem(props: CardItemProps) {
  const { data } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useLoading([
    getLoadingByKey(template.loadings.removeById, data.id),
  ]);

  const onDelete = useCallback(() => {
    dispatch(
      template.actions.removeById({
        id: data.id,
        _actionKey: data.id,
        success() {
          dispatch(templateList.actions.fetch(undefined));
        },
      })
    );
  }, [data, dispatch]);

  return (
    <div
      key={data.id}
      className={styles.templeteItem}
      style={{  }}
    >
      <div className={styles.bottom}>
        <div className={styles.title}>Title: {data.templateName}</div>
        <div className={styles.title}>
          Created: {dayjs(data.createdAt).format('YYYY-MM-DD hh:mm')}
        </div>
        {data.lastModifiedAt != null &&
          <div className={styles.title}>
            Modified: {dayjs(data.lastModifiedAt).format('YYYY-MM-DD hh:mm')}
          </div>
        }
      </div>
      <div className={styles.mask}>
        {loading ? (
          <div className={styles.listBottom}>
            <Loading loading color='#ffffff' />
          </div>
        ) : (
          <div className={styles.listBottom}>
            <div className={styles.listItem}>
              <Popconfirm
                title='Are you want to delete it?'
                onConfirm={onDelete}
                okText='Ok'
                cancelText='Cancel'
              >
                <IconDelete />
                &nbsp;Delete
              </Popconfirm>
            </div>
            <div className={styles.listItem}>
              <Link
                to={`/editor?id=${data.id}`}
              >
                <IconEdit />
                &nbsp;Edit
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
