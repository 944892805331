import { emailTemplate, ITemplate } from '../services/email-template';
import createSliceState from './common/createSliceState';

export default createSliceState({
  name: 'templateList',
  initialState: [] as ITemplate[],
  reducers: {
    set: (state, action) => state,
  },
  effects: {
    fetch: async (state) => {
      const list = await emailTemplate.getEmailTemplateList({});
      list.sort((a, b) => (a.createDate > b.createDate ? -1 : 1));
      return list;
    },
  },
});
